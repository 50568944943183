<template>
    <div class="information">
        <div class="content_box_">
            <div class="flower_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG543.png" alt=""></div>
            <div class="title_bg_text_">匹配契合度</div>
            <div class="name_box_">
                <div class="grade_box_">{{data_list.fallinmatch.score}}</div>
                <div class="name_left_box name_box_common"><div class="name_text">{{data_list.scwg.self_scwg.name}} <br> <p>男主角</p></div></div>
                <div class="name_right_box name_box_common"><div class="name_text">{{data_list.scwg.other_scwg.name}} <br> <p>女主角</p></div></div>
            </div>
            <p class="text_">{{data_list.fallinmatch.str}}</p>
            <p class="text_">
                特别说明:此分数为双方感情契合度参考分数，反
                应的是测算双方的感情状况，若遇到分数较高或
                较低，都需要重视经营自己的感情，通过后天努
                力弥补当前感情契合度的不足，争取有好的结果
                发生。<br><br>若仍有疑惑，可联系道长为您解答 
            </p>
        </div>
        <div class="content_box_">
                <div class="flower_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG543.png" alt=""></div>
                <div class="title_bg_text_">男女姓名婚姻五格</div>
               <div class="user_name_text_">男主角：{{data_list.scwg.self_scwg.name}}</div>
               <div class="img_geomantic_box" v-if='man_name_number === 2'>
                   <div class="div_text_box" style="top:1.05rem;left:.4rem">
                       外格
                        <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['外']}}</span>
                   </div>
                   <div class="center_box__">
                        <div class="big_box_">
                            <div class="across_"></div>
                            天格
                            <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['天']}}</span>
                        </div>
                        <div class="big_box_" style="top:.88rem">
                            <div class="across_"></div>
                            人格
                            <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['人']}}</span>
                        </div>
                        <div class="big_box_" style="top:1.76rem">
                            <div class="across_"></div>
                            地格
                            <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['地']}}</span>
                        </div>
                   </div>
                    <div class="right_box__" >
                        <div class="img_box__"><img src="https://cdn.zhiyileiju.cn/WechatIMG545.pic" alt=""></div>
                        <div class="big_box_"  style="top:0rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.self_scwg.name[0]}}
                        </div>
                        <div class="big_box_"  style="top:.88rem;font-size:.48rem;padding-left: .8rem;">
                            <!-- <div class="across_"></div>
                            {{data_list.scwg.self_scwg.name[1]}} -->
                        </div>
                        <div class="big_box_" style="top:1.76rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.self_scwg.name[1]}}
                        </div>
                        <div class="div_text_box" style="left:1.9rem;top:1.05rem">
                       总格
                        <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['总']}}</span>
                   </div>
                    </div>
    

               </div>
               <div class="img_geomantic_box" v-if='man_name_number === 3'>
                   <div class="div_text_box" style="top:1.05rem;left:.4rem">
                       外格
                        <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['外']}}</span>
                   </div>
                   <div class="center_box__">
                        <div class="big_box_">
                            <div class="across_"></div>
                            天格
                            <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['天']}}</span>
                        </div>
                        <div class="big_box_" style="top:.88rem">
                            <div class="across_"></div>
                            人格
                            <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['人']}}</span>
                        </div>
                        <div class="big_box_" style="top:1.76rem">
                            <div class="across_"></div>
                            地格
                            <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['地']}}</span>
                        </div>
                   </div>
                    <div class="right_box__" >
                        <div class="img_box__"><img src="https://cdn.zhiyileiju.cn/WechatIMG545.pic" alt=""></div>
                        <div class="big_box_"  style="top:0rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.self_scwg.name[0]}}
                        </div>
                        <div class="big_box_"  style="top:.88rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.self_scwg.name[1]}}
                        </div>
                        <div class="big_box_" style="top:1.76rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.self_scwg.name[2]}}
                        </div>
                        <div class="div_text_box" style="left:1.9rem;top:1.05rem">
                       总格
                        <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['总']}}</span>
                   </div>
                    </div>
    

               </div>
               <div class="img_geomantic_box" v-if="man_name_number == 4" style="height: 3.6rem;">
                   <div class="div_text_box" style="top:1.55rem;left:.4rem">
                       外格
                        <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['外']}}</span>
                   </div>
                   <div class="center_box__">
                        <div class="big_box_" style="top:.46rem">
                            <div class="across_"></div>
                            天格
                            <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['天']}}</span>
                        </div>
                        <div class="big_box_" style="top:1.34rem">
                            <div class="across_"></div>
                            人格
                            <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['人']}}</span>
                        </div>
                        <div class="big_box_" style="top:2.22rem">
                            <div class="across_"></div>
                            地格
                            <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['地']}}</span>
                        </div>
                   </div>
                    <div class="right_box__" >
                        <div class="img_box__" style="width: 0.5rem;left: 1.35rem;"><img src="https://cdn.zhiyileiju.cn/WechatIMG545.pic" alt=""></div>
                        <div class="big_box_" style="top:0rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.self_scwg.name[0]}}
                        </div>
                        <div class="big_box_" style="top:.88rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.self_scwg.name[1]}}
                        </div>
                        <div class="big_box_" style="top:1.76rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.self_scwg.name[2]}}
                        </div>
                        <div class="big_box_" style="top:2.64rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.self_scwg.name[3]}}
                        </div>
                        <div class="div_text_box" style="left:1.9rem;top:1.44rem">
                       总格
                        <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['总']}}</span>
                   </div>
                    </div>
               </div>
               
               <div class="user_name_text_">女主角：{{data_list.scwg.other_scwg.name}}</div>
               <div class="img_geomantic_box" v-if='girl_name_number === 2'>
                   <div class="div_text_box" style="top:1.05rem;left:.4rem">
                       外格
                        <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['外']}}</span>
                   </div>
                   <div class="center_box__">
                        <div class="big_box_">
                            <div class="across_"></div>
                            天格
                            <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['天']}}</span>
                        </div>
                        <div class="big_box_" style="top:.88rem">
                            <div class="across_"></div>
                            人格
                            <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['人']}}</span>
                        </div>
                        <div class="big_box_" style="top:1.76rem">
                            <div class="across_"></div>
                            地格
                            <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['地']}}</span>
                        </div>
                   </div>
                    <div class="right_box__" >
                        <div class="img_box__"><img src="https://cdn.zhiyileiju.cn/WechatIMG545.pic" alt=""></div>
                        <div class="big_box_"  style="top:0rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.other_scwg.name[0]}}
                        </div>
                        <div class="big_box_"  style="top:.88rem;font-size:.48rem;padding-left: .8rem;">
                            <!-- <div class="across_"></div>
                            {{data_list.scwg.other_scwg.name[1]}} -->
                        </div>
                        <div class="big_box_" style="top:1.76rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.other_scwg.name[1]}}
                        </div>
                        <div class="div_text_box" style="left:1.9rem;top:1.05rem">
                       总格
                        <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['总']}}</span>
                   </div>
                    </div>
    

               </div>
               <div class="img_geomantic_box" v-if='girl_name_number === 3'>
                   <div class="div_text_box" style="top:1.05rem;left:.4rem">
                       外格
                        <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['外']}}</span>
                   </div>
                   <div class="center_box__">
                        <div class="big_box_">
                            <div class="across_"></div>
                            天格
                            <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['天']}}</span>
                        </div>
                        <div class="big_box_" style="top:.88rem">
                            <div class="across_"></div>
                            人格
                            <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['人']}}</span>
                        </div>
                        <div class="big_box_" style="top:1.76rem">
                            <div class="across_"></div>
                            地格
                            <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['地']}}</span>
                        </div>
                   </div>
                    <div class="right_box__" v-if='girl_name_number === 3'>
                        <div class="img_box__"><img src="https://cdn.zhiyileiju.cn/WechatIMG545.pic" alt=""></div>
                        <div class="big_box_"  style="top:0rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.other_scwg.name[0]}}
                        </div>
                        <div class="big_box_"  style="top:.88rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.other_scwg.name[1]}}
                        </div>
                        <div class="big_box_" style="top:1.76rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.other_scwg.name[2]}}
                        </div>
                        <div class="div_text_box" style="left:1.9rem;top:1.05rem">
                       总格
                        <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['总']}}</span>
                   </div>
                    </div>
                    
               </div>
               <div class="img_geomantic_box" v-if='girl_name_number === 4' style="height: 3.6rem;">
                   <div class="div_text_box" style="top:1.55rem;left:.4rem">
                       外格
                        <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['外']}}</span>
                   </div>
                   <div class="center_box__">
                        <div class="big_box_" style="top:.46rem">
                            <div class="across_"></div>
                            天格
                            <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['天']}}</span>
                        </div>
                        <div class="big_box_" style="top:1.34rem">
                            <div class="across_"></div>
                            人格
                            <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['人']}}</span>
                        </div>
                        <div class="big_box_" style="top:2.22rem">
                            <div class="across_"></div>
                            地格
                            <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['地']}}</span>
                        </div>
                   </div>
                    <div class="right_box__" >
                        <div class="img_box__" style="width: 0.5rem;left: 1.35rem;"><img src="https://cdn.zhiyileiju.cn/WechatIMG545.pic" alt=""></div>
                        <div class="big_box_" style="top:0rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.other_scwg.name[0]}}
                        </div>
                        <div class="big_box_" style="top:.88rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.other_scwg.name[1]}}
                        </div>
                        <div class="big_box_" style="top:1.76rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.other_scwg.name[2]}}
                        </div>
                        <div class="big_box_" style="top:2.64rem;font-size:.48rem;padding-left: .8rem;">
                            <div class="across_"></div>
                            {{data_list.scwg.other_scwg.name[3]}}
                        </div>
                        <div class="div_text_box" style="left:1.9rem;top:1.44rem">
                       总格
                        <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['总']}}</span>
                   </div>
                    </div>
               </div>
        </div>
    </div>
</template>

<script>
import resultData from "../../../mixin/nameMatching/result_content/resultData"
export default {
     mixins:[resultData],
}
</script>

<style lang="scss" scoped>
    .information{
        .content_box_{
            position: relative;
            width: 7.18rem;
            // height: 8.59rem;
            margin: 0 auto;
            background: #fff;
            border-radius: .30rem;
            box-shadow: 0 0 5px #e8c7d6;
            padding-top: .24rem;
            padding-bottom: .6rem;
            margin-bottom: .47rem;
            margin-top: 0.1rem;
            .flower_img_{
                position: absolute;
                width: 7.18rem;
                top: 0;
            }
            .title_bg_text_{
                width: 4.47rem;
                height: .73rem;
                margin: 0 auto;
                text-align: center;
                color: #C4393E;
                font-size: .38rem;
                font-weight: bold;
                line-height: .73rem;
                background: url('https://cdn.zhiyileiju.cn/WechatIMG544.jpeg') no-repeat;
                background-size: 100% 100%;
            }
            .name_box_{
                position: relative;
                width: 6.27rem;
                height: 1.41rem;
                margin: .55rem auto;
                .grade_box_{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 1.4rem;
                    height: 1.4rem;
                    font-size: .72rem;
                    font-weight: bold;
                    color: #FFA3B2;
                    text-align: center;
                    line-height: 1.4rem;
                    border-radius: 1.4rem;
                    background: #fff;
                }
                .name_box_common{
                    width: 50%;
                    height: 1.41rem;
                    .name_text{
                        font-size: .36rem;
                        font-weight: bold;
                        color: #3D659A;
                        padding-left: .28rem;
                        margin-top: .35rem;
                        p{
                            // font-size: .18rem;
                            font-size: .23rem;
                            font-weight: 400;
                            // margin-top: .18rem;
                        }
                    }
                }
                .name_left_box{
                    float: left;
                    border-top-left-radius: .1rem;
                    border-bottom-left-radius: .1rem;
                    background: #C7DFFF;
                }
                .name_right_box{
                    float: right;
                    border-top-right-radius: .1rem;
                    border-bottom-right-radius: .1rem;
                    background: #FECCDD;
                    .name_text{
                        text-align: right;
                        padding-right: .28rem;
                        color: #943858;
                    }
                }
            }
            .text_{
                padding: 0 .5rem;
                font-size: .28rem;
                color: #525252;
                line-height: .48rem;margin-top: .35rem;
            }
            .text_:last-child{
                margin-top: .37rem;
                color: #67386F;
            }
            .overstriking_{
                font-weight: bold;
            }
            .score_box_{
                display: inline-block;
                .star_box_{
                    display: inline-block;
                    width: .34rem;
                    margin-right: .08rem;
                }
            }
            .text_red__{
                color: #C4393E;
            }
            
            .title_text__{
                font-size: .28rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #C4393E;
                text-align: center;
                margin-top: .36rem;
                margin-bottom: .28rem;
            }
            
            .user_name_text_{
                padding-left: .37rem;
                font-size: .32rem;
                font-weight: bold;
                color: #525252;
                 margin-top: .86rem;
            }
            .user_name_text_:last-child{
                margin-top: 0;
            }
            .img_geomantic_box{
                position: relative;
                height: 2.6rem;
                margin-top: .6rem;
                .div_text_box{
                    width: 1.2rem;
                    // display: inline-block;
                    position: absolute;
                    height: .49rem;
                    font-size: .28rem;
                    color: #525252;
                    line-height: .49rem;
                }
                    .diamond_box{
                        display: inline-block;
                        width: .49rem;
                        height: .49rem;
                        line-height: .49rem;
                        text-align: center;
                        color: #fff;
                        background: #D74A50;
                    }
                .big_box_{
                    position: absolute;
                    width: 2rem;
                    height: .88rem;
                    font-size: .28rem;
                    color: #525252;
                    line-height: .88rem;
                    box-sizing: border-box;
                    border-left: .05rem solid #D74A50;
                    padding-left: .59rem;
                    .across_{
                        position: absolute;
                        top: 50%;
                        left: -.001rem;
                        transform: translate(0,-50%);
                        width: .44rem;
                        height: .05rem;
                        background: #D74A50;

                    }
                }
                .center_box__{
                    position: absolute;
                    left: 1.8rem;
                }
                .right_box__{
                    position: absolute;
                    right: 3.3rem;
                    .img_box__{
                        position: absolute;
                        left: 1.45rem;
                        top: .4rem;
                        width: .35rem;
                    }
                    
                }
            }
            .gossip_bg_box_{
                width: 7.18rem;
                height: 1.52rem;
                background: url('https://cdn.zhiyileiju.cn/WechatIMG547.jpeg') no-repeat;
                background-size: 100% 100%;
                margin-top: .52rem;
                margin-bottom: .46rem;
                    text-align: center;
                .name_text__{
                    color: #525252;
                    font-size: .36rem;
                    margin-top: .84rem;
                    line-height: .35rem;
                    width: 1.7rem;
                    .designation__{
                        display: inline-block;
                        color: #9C9C9C;
                        font-size: .23rem;
                        // transform: scale(1.4);
                    }
                }
                .man_name_{
                    float: left;
                    margin-left: .4rem;
                }
                .girl_name_{
                    float: right;
                     margin-right: .4rem;
                }
            }
            
        }
         
    }
</style>